'use client';

import React, { useState, useMemo } from 'react';
import ProductCard from '@components/ProductCard-2';
import { IProductCarouselItem } from './types';
import { productSwatches } from '@utils/productTools';

const ProductItem = ({ item, showPrice }: { item: IProductCarouselItem; showPrice: boolean }) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const { image, isYotpoEnabled, name, price, ratingSummary, urlKey, productItemUrl, uuid, isBestseller } = item;

    const moveActiveFirst = (swatches) => {
        const activeIndex = swatches.findIndex((swatch) => swatch.active);

        if (activeIndex <= 0) {
            return swatches;
        }

        const reordered = [...swatches];
        const [activeSwatch] = reordered.splice(activeIndex, 1);
        reordered.unshift(activeSwatch);

        return reordered;
    };

    const setActiveSwatches = (swatchesArray, selectedSwatchId) => {
        swatchesArray.forEach((initialSwatch) => {
            const imageMatch = /^(.*)(?=(\?)).*/.exec(initialSwatch.thumbnail);
            const isMainImageMatchesSwatch = imageMatch?.[1] ? item.imageUrl.includes(imageMatch[1]) : false;

            if (initialSwatch.id === selectedSwatchId || (!selectedItem && isMainImageMatchesSwatch)) {
                initialSwatch.active = true;
            } else {
                initialSwatch.active = false;
            }
        });
    };

    const initialSwatches = useMemo(() => {
        const allSwatches = productSwatches(productItemUrl, item.configurableOptions, item.productVariants);
        setActiveSwatches(allSwatches, selectedItem);
        return moveActiveFirst(allSwatches); // Reorder only on first render
    }, []);

    const swatches = useMemo(() => {
        setActiveSwatches(initialSwatches, selectedItem);

        return initialSwatches;
    }, [selectedItem]);

    // Get the image for the selected swatch
    const productImage = useMemo(() => {
        const variant = selectedItem && swatches.find(({ id }) => id === selectedItem);
        return variant?.mainImage || false;
    }, [swatches, selectedItem]);

    const newPrice = price && { ...price, showRange: true };

    // Handle swatch selection
    const handleSwatchSelection = (id: string) => {
        setSelectedItem(id);
    };

    return (
        <ProductCard
            isNewVersion
            image={{
                ...image,
                fileReference: productImage || image.fileReference,
            }}
            name={name}
            price={showPrice ? newPrice : undefined}
            urlKey={urlKey}
            yotpoRating={{ average_score: ratingSummary }}
            isYotpoEnabled={isYotpoEnabled}
            swatches={swatches}
            handleSwatchSelection={handleSwatchSelection}
            uuid={uuid}
            isBestseller={isBestseller}
        />
    );
};

export default ProductItem;
