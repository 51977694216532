import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Icon from '@components/Icon';
import Image from '@components/Image';
import DiscountBadge from '@components/DiscountBadge';
import type { ISwatchSelector } from './types';
import { IconTypesEnum } from '@components/Icon/types';
import styles from './styles/swatchSelector.module.scss';

const filterRestProps = (props) => {
    const { swatchValue, hasColor, mainImage, ...filteredValues } = props;
    return filteredValues;
};

const IMAGE_SIZE = '44px';
const SwatchSelector = (props: ISwatchSelector): ReactElement | null => {
    const {
        active = false,
        className,
        color,
        disabled = false,
        hasDiscount,
        id,
        image,
        isLightColor,
        handleSwatchSelection,
        isSmall = true,
        label,
        onClick,
        onSwatchHover,
        to,
        ...rest
    } = props;
    if (!color && !image) return null;

    const handleClick = handleSwatchSelection ? () => handleSwatchSelection(id) : onClick;
    const classes = clsx(className, styles.root, active && styles.active, isSmall && styles.small);
    const iconContent = (
        <Icon
            className={clsx(styles.icon, active && styles.iconActive, isLightColor && styles.iconLightColor)}
            name={'checkmark'}
            type={IconTypesEnum.ICON}
            size={isSmall ? '12' : '24'}
        />
    );
    const imageContent = image && (
        <Image
            image={{
                alt: label,
                dimensionsType: 'auto',
                fileReference: image,
                height: IMAGE_SIZE,
                width: IMAGE_SIZE,
            }}
            classes={{
                image: styles.image,
            }}
        />
    );

    const children = (
        <>
            {imageContent}
            {iconContent}
        </>
    );

    if (to) {
        return (
            <div className={clsx(hasDiscount && styles.swatchContainer)}>
                <Link
                    className={classes}
                    to={to as string}
                    onClick={handleClick}
                    style={{ backgroundColor: `${color}` }}
                    title={label}
                    {...filterRestProps(rest)}
                >
                    {<span className={styles.overlayImage}></span>}
                    {children}
                </Link>
                {hasDiscount && <DiscountBadge isSmallOnMobile />}
            </div>
        );
    }

    return (
        <div className={clsx(styles.swatchContainer)}>
            <button
                aria-disabled={disabled}
                aria-label={label}
                className={classes}
                disabled={disabled}
                onClick={handleClick}
                onFocus={onSwatchHover}
                onMouseOver={onSwatchHover}
                title={label}
                type={'button'}
                style={{ backgroundColor: `${color}` }}
                {...filterRestProps(rest)}
            >
                {<span className={styles.overlayImage}></span>}
                {children}
            </button>
            {hasDiscount && <DiscountBadge isSmallOnMobile />}
        </div>
    );
};

export default SwatchSelector;
